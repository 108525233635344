import { useContext, useState } from 'react'
import { CartContext } from '../../store/CartContext/CartContext'
import './FormNuevo.scss'

const FormNuevo = ({ setShowModalPlato, titulo, category, bodega, setPlatos }) => {
    const { PUBLICURL, getAll } = useContext(CartContext)
    const [stateVegano, setStateVegano] = useState(false)
    const [stateBodega, setStateBodega] = useState(false)
    const [form, setForm] = useState({
        nombre: 'nombre del plato',
        vegano: false,
        descripcion: '',
        precio: 0,
        bodega: '',
        category,
        titulo
    })
    const handleInputChange = (e) => {
        if (e.target.name === 'vegano') {
            setForm(({
                ...form,
                [e.target.name]: e.target.checked
            }))
        } else {
            setForm(({
                ...form,
                [e.target.name]: e.target.value
            }))
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        // if( form.bodega !== '' ){
        setShowModalPlato(false)
        const url = PUBLICURL
        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(form),
        })

        if (response.ok) {
            let res = await getAll(category)
            setPlatos(res.menu)
        } else {
            console.error(response)
        }
        // }else{
        //     console.log('bodega error')
        // }

    }

    return (
        <form className='formulario' onSubmit={handleSubmit}>
            <div>
                <label>Nombre del plato</label>
                <input placeholder='nombre' type='text' onChange={handleInputChange} name='nombre' />
            </div>
            <div>
                <label>Precio</label>
                <input placeholder='precio' type='number' onChange={handleInputChange} name='precio' />
            </div>
            {titulo === 'Vinos' ? (
                <div>
                    <label>Bodega</label>
                    <input placeholder='Bodega' type='text' onChange={handleInputChange} name='bodega' disabled={stateBodega} />
                </div>
            ) : ''}
            {titulo === 'Vinos' ? (
                <div>
                    <label>Sin Bodega</label>
                    <input type='checkbox' checked={stateBodega} onClick={() => setStateBodega(!stateBodega)} onChange={handleInputChange} name='bodega' />
                </div>
            ) : ''}
            <div>
                <label>Descripción</label>
                <textarea placeholder='descripcion' onChange={handleInputChange} name='descripcion' />
            </div>
            <label>Vegano</label>
            <input type='checkbox' checked={stateVegano} onClick={() => setStateVegano(!stateVegano)} onChange={handleInputChange} name='vegano' />
            <button type="submit">Submit</button>
        </form>
    )
}
export default FormNuevo