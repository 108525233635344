import { createContext, useState } from "react"
const CartContext = createContext()

const CartProvider = ({ children }) => {

    const [carta, setCarta] = useState(false)
    let [platos, setPlatos] = useState([])
    const [titulos, setTitulos] = useState([])
    const [login, setLogin] = useState(false)
    const [bodegas, setBodegas] = useState([])
    const arrayTest = []
    // let PUBLICURL = `http://localhost:1500/`
    let PUBLICURL = `https://api.fhdev.com.ar/`
    // let PUBLICURL = `https://jeremias.fhdev.com.ar/`

    const getMenu = async ruta => {
        const URL = `${PUBLICURL}${ruta}`
        let menu = await fetch(URL,
            { cache: "reload" }
        )
        return menu.json()
    }

    const newRender = (ruta) => {
        getMenu(ruta).then((menu) => {
            setPlatos(menu)
            let listado = []
            menu.map((plato) => {
                let titulo = false
                titulo = listado.find(titulo => titulo === plato.titulo)
                if (!titulo) {
                    listado.push(plato.titulo)
                }
            })
            setTitulos(listado)
            let listadoBodegas = []
            menu.map((plato) => {
                if (plato.bodega) {
                    let bodega = false
                    bodega = listadoBodegas.find(bodega => bodega === plato.bodega)
                    if (!bodega) {
                        listadoBodegas.push(plato.bodega)
                    }
                }
            })
            setBodegas(listadoBodegas)
        })
    }

    const getAll = async (ruta) => {
        const URL = `${PUBLICURL}${ruta}`

        let menu = await fetch(URL,
            { cache: "reload" }
        )
        menu = await menu.json()
        setPlatos(menu)
        let listado = []
        menu.map((plato) => {
            let titulo = false
            titulo = listado.find(titulo => titulo === plato.titulo)
            if (!titulo) {
                listado.push(plato.titulo)
            }
        })
        setTitulos(listado)
        let listadoBodegas = []
        menu.map((plato) => {
            if (plato.bodega) {
                let bodega = false
                bodega = listadoBodegas.find(bodega => bodega === plato.bodega)
                if (!bodega) {
                    listadoBodegas.push(plato.bodega)
                }
            }
        })
        setBodegas(listadoBodegas)

        return { listado, menu }
        // return { titulos: listado, platos: menu }

    }


    const data = {
        setCarta,
        getMenu,
        platos,
        setPlatos,
        titulos,
        setTitulos,
        carta,
        newRender,
        login,
        setLogin,
        bodegas,
        setBodegas,
        arrayTest,
        PUBLICURL,
        getAll
    }
    return (
        <CartContext.Provider value={data}>
            {children}
        </CartContext.Provider>
    )
}

export { CartProvider, CartContext }